import { Platform, Project, ProjectType, Tag } from '../types'

export const projects: Project[] = [
  {
    id: '43011847d0927ccecf77d4609372ba3a727cb0c5',
    name: 'A Simple Chess Game',
    description:
      'A Simple Chess Game is a chess game for iOS that supports 2-player gameplay to play against your friends.',
    responsibilities: ['Everything'],
    tags: new Set([Tag['Objective-C']]),
    teamSize: 1,
    platforms: new Set([Platform['mobile'], Platform['game']]),
    type: ProjectType['Open'],
    icon: 'iconASChess.png',
    sampleCodeUrl: 'https://github.com/SpiritBreaker226/asimplechessgame',
    isFeature: false,
  },
  {
    id: 'd85e18a48dde748c472caa41c6dae45d5461ab4f',
    name: 'OKCouncillr',
    description:
      'OKCouncillr is a data scraper, API, and civic engagement app for the City of Toronto. The scraper pulls data from the City of Toronto council meetings and parses it into a database, the API serves the data up as JSON, and the app allows people to discover how their Councillor matches their political leanings.',
    responsibilities: [
      "Created and maintained the API, which allows other developers access to the City of Toronto's data which the application scraped from their site.",
      'Implemented the database diagram that my group and I had created.',
      'I assisted with the application and the scraper, creating a fully functional application and scraper.',
    ],
    tags: new Set([
      Tag['Ruby'],
      Tag['Rails'],
      Tag['PostgreSQL'],
      Tag['JavaScript'],
      Tag['CSS'],
      Tag['HTML'],
    ]),
    teamSize: 3,
    platforms: new Set([Platform['web']]),
    type: ProjectType['Open'],
    icon: 'iconOKCouncillr.svg',
    sampleCodeUrl: 'https://github.com/CivicHaxx/ok_councillr',
    isFeature: false,
  },
  {
    id: 'a9ff2fa44a6c138fe9605337d6c85563485f22c3',
    name: 'Elementary Teachers of Toronto',
    description:
      "Elementary Teachers of Toronto (ETT) is the Toronto local of the Elementary Teachers' Federation of Ontario (ETFO). The local—the largest in Canada—acts as the representative body for more than 11,000 elementary teachers (K-8) employed by the Toronto District School Board (TDSB). In addition, we worked with a partner company (design vendor) to develop the site's frontend using WordPress as the backbone, which supported the integration with their current organizational structure and organizing events for their members.",
    responsibilities: [
      'Lead Developer on the project',
      'I developed the backend for the Member data management system to support the growth and marketing initiatives of ETT organization and re-engineered WordPress so that multiple plugins can post onto the website.',
      'Add, in code, that would use the same data and layout but would detect if there was a file for a different post type. If so, the function would send the data to that file and use it instead.',
    ],
    tags: new Set([
      Tag['PHP'],
      Tag['mySQL'],
      Tag['JavaScript'],
      Tag['CSS'],
      Tag['HTML'],
    ]),
    teamSize: 2,
    platforms: new Set([Platform['web']]),
    type: ProjectType['Close'],
    icon: 'iconETT.png',
    sampleCodeUrl: 'https://github.com/SpiritBreaker226/codesamples-ETT',
    isFeature: false,
  },
  {
    id: 'd1ed515f27f28e290e12da95e6acffc0fef85e9c',
    name: 'Oxford Properties Recycling Game',
    description:
      'Oxford Properties, a real estate properties company with a team of 1,700, takes a hands-on approach to investment, development and management of their global property portfolio. I developed an iPad game for Oxford Properties to promote recycling and sustainability in their buildings. It gave the tenants a fun way of learning what is recyclable.',
    responsibilities: [
      'Lead Developer on the project',
      "I developed an image uploader and manager component that keeps track of the user's image that they uploaded for either their obituary or funeral home.",
      'I mentored junior developers on the game procedure and maintenance.',
      'Added drag and drop function to allow the user in the game to move the trash into the proper bins, then award points if they are correct.',
      'I developed the deployment plan and trained the client on installation and reporting procedures.',
      'The game also needed to be bilingual. I added a JSON file with all of the text for the game in both English and French, which allows the game not only to be in two languages but for further expansion into other languages, if necessary.',
    ],
    tags: new Set([
      Tag['C#'],
      Tag['JavaScript'],
      Tag['CSS'],
      Tag['HTML'],
      Tag['SQLServer'],
      Tag['Phonegap'],
    ]),
    teamSize: 2,
    platforms: new Set([Platform['mobile'], Platform['game']]),
    type: ProjectType['Close'],
    icon: 'iconOxford.png',
    sampleCodeUrl:
      'https://github.com/SpiritBreaker226/codesamples-Oxford-RecyclingKiosk',
    isFeature: true,
  },
  {
    id: 'e3b71db13db244c95da199cbe3d95c5c6ad39762',
    name: 'GeriatRx Pharmacy',
    description:
      "GeriatRx Pharmacy is a unique player in the LTC (Long Term Care) industry. Their mission is to provide unparalleled service to their LTC clientele. I created an app to allow doctors to view their patient's history and drug interactions and enter their patient's prescriptions from a tablet.",
    responsibilities: [
      'Lead Developer on the project',
      'I worked on the styling and most of the coding. This app can search by patient’s name and by drugs. The doctor can authorize by signing a prescription on the app. A PDF is automatically sent to the doctor and patient.',
      'Finding the patient and drugs was time-consuming. Creating an instant search for patients and drugs cuts the processing time in half and allows doctors to prescribe prescriptions to their patients more quickly.',
    ],
    tags: new Set([
      Tag['C#'],
      Tag['JavaScript'],
      Tag['CSS'],
      Tag['HTML'],
      Tag['SQLServer'],
      Tag['Phonegap'],
    ]),
    teamSize: 2,
    platforms: new Set([Platform['mobile']]),
    type: ProjectType['Close'],
    icon: 'iconGeriatRx.png',
    isFeature: false,
  },
  {
    id: '5a11e98b05b31bc1d9d4528f1b73d9e1c59053f4',
    name: 'Ontario Liberal Party',
    description:
      'I implemented two new content-managed websites for the Ontario Liberal Party. It had a short timeline and rapid development for September 2010 release. The first website accommodated the DotNetNuke CMS engine, and the second website performed custom integration with OLP internal web services that were utilized as a data source.',
    responsibilities: [
      'I was the sole Developer on the project, and other team members were a designer and Q&A testers.',
      'There are two RSS feeds for the video section, YouTube and Vimeo. I have created a way to read and display the videos for those feeds.',
      'I created a function to read a non-web standard from their non-standard XML stream and converted it over to be read onto the website; this resulted in internal data being displayed on their website, meaning less administration.',
    ],
    tags: new Set([
      Tag['C#'],
      Tag['JavaScript'],
      Tag['CSS'],
      Tag['HTML'],
      Tag['SQLServer'],
    ]),
    teamSize: 4,
    platforms: new Set([Platform['web']]),
    type: ProjectType['Close'],
    icon: 'iconOLP.png',
    isFeature: true,
  },
  {
    id: '21e4d3134d22e7a30acbaf8a084610d098c08882',
    name: 'theObituaries.ca',
    description:
      'The Obituaries is a site that allows you to create an obituary or memorial for a friend or family member. You can also share obituaries on Facebook or Twitter.',
    responsibilities: [
      'Lead Developer on the project',
      'I was Masterminded in implementing the design, local database and game algorithm on a tight deadline.',
    ],
    tags: new Set([
      Tag['C#'],
      Tag['JavaScript'],
      Tag['CSS'],
      Tag['HTML'],
      Tag['SQLServer'],
      Tag['PHP'],
      Tag['mySQL'],
    ]),
    teamSize: 5,
    platforms: new Set([Platform['web']]),
    type: ProjectType['Close'],
    icon: 'iconObituaries.png',
    sampleCodeUrl:
      'https://github.com/SpiritBreaker226/codesamples-theobituaries',
    isFeature: false,
  },
  {
    id: 'c1d3506b343b10d8ab7ba2ba9c70fa4a07d1da92',
    name: 'Chatr Wireless',
    description:
      'Chatr Wireless is a subsidiary of Rogers Communication and focuses on providing quick and less expensive plans for phone and text. I coordinated the execution of a full-cycle online/mobile contest with a short deadline and restricted budget for Rogers Chatr Wireless customers. The online portion of the contest allowed Rogers Chatr wireless customers to enter a valid Chatr Wireless phone number to participate in the contest and possibly win prizes. In addition, they would receive text messages if they won an award or receive a number to enter into the website.',
    responsibilities: [
      'I was the lead developer of the project when the contest launched and maintained the site and reports while the contest was going on.',
      'I developed a complex algorithm to manage data duplication and prize allocation.',
      'I developed Q&A plan and benchmark measures to validate data integrity and guided our internal team in understanding complex requirements within the context of time and budget restraints.',
      'I created a complex backend routine to consume daily .csv data files with customer data through a secure FTP connection to provide reporting of the contest to Chatr.',
      'I performed application troubleshooting and developed comprehensive reporting.',
    ],
    tags: new Set([
      Tag['C#'],
      Tag['JavaScript'],
      Tag['CSS'],
      Tag['HTML'],
      Tag['SQLServer'],
    ]),
    sampleCodeUrl: 'https://github.com/SpiritBreaker226/codesamples-Chatr',
    teamSize: 2,
    platforms: new Set([Platform['web']]),
    type: ProjectType['Close'],
    icon: 'iconChatr.png',
    isFeature: false,
  },
  {
    id: 'f4cad025a5ad80e6f1c185941ebf4f0c9c5f8602',
    name: "Benjamin Memorial Chapel's Email Program",
    description:
      "The Benjamin Memorial Chapel site is part of the Benjamin Group of sites that allow visitors to be informed of the loss of a loved one and provide information to the visitors, of site, about Jewish funnel traditions. In addition, they wanted an email program to send out newsletters twice daily to their subscribers about the coming funeral services at Benjamin's.",
    responsibilities: [
      'I maintained and improved existing code',
      'The client wanted a newsletter detailing services at the chapel in the next seven days. Reinstated and maintained the newsletter. It resulted in over 1000 subscribers receiving this newsletter twice a day.',
      'On most Jewish Holidays, there is a message that needs to go up to the website and newsletter at different points during that holiday. I created a function using Test Driven Development to display a holiday message for a given time on both the website and newsletter, which resulted in less work in maintaining the website and newsletter during those days.',
    ],
    tags: new Set([Tag['C#'], Tag['SQLServer']]),
    teamSize: 3,
    platforms: new Set([Platform['web']]),
    type: ProjectType['Close'],
    icon: 'iconBenjamin.png',
    url: 'https://www.benjaminsparkmemorialchapel.ca',
    sampleCodeUrl: 'https://github.com/SpiritBreaker226/codesamples-Benjamins',
    isFeature: true,
  },
  {
    id: 'af9a9e5b591f165e19c4f6e768301c692a0ae646',
    name: 'Northcott',
    description:
      'Northcott is an International Distributor and Converter of fine cotton printed fabrics for the quilt, craft and home decor industries. Created an eShop app to allow the sales people to quickly place orders of fabric and designs, while they are with their customers in their shop or home.',
    responsibilities: [
      'Lead Developer on the project',
      'I Added complex business rules from Northcott. For example, a customer in Canada can buy all fabrics in half the quantity. However, this only applies to fabrics that are metre(s). If the material is inches, it should be in the cart but at 0 quantity.',
      'I tied the existing online ordering system, which uses Java and ColdFusion, with the app, which uses C#. By calling the page already being used for online ordering, we were able to add the items in the cart from the app into the existing ordering system, resulting in a unified ordering system.',
    ],
    tags: new Set([
      Tag['C#'],
      Tag['JavaScript'],
      Tag['CSS'],
      Tag['HTML'],
      Tag['SQLServer'],
      Tag['Phonegap'],
    ]),
    teamSize: 2,
    platforms: new Set([Platform['mobile']]),
    type: ProjectType['Close'],
    icon: 'iconNorthcott.png',
    sampleCodeUrl: 'https://github.com/SpiritBreaker226/codesamples-Nothcott',
    isFeature: false,
  },
  {
    id: '1f23d276aafeb6d5a3cd0290cf5ab61b90e414c6',
    name: 'City of Vaughan',
    description:
      'The official app for the City of Vaughan allows its citizens to know what is going on in Vaughan and contact information for their city hall.',
    responsibilities: [
      'Lead Developer on the project.',
      'I redesigned the app from the bottom up because of duplicated code, a slow user interface and hard to maintain; this resulted in a clean code and quicker user interface, which is now easier to maintain.',
      "Completed a Carousel for the homepage that will display feature events and news to the user when the app loads, as well as a created management system to allow the administrators at the City of Vaughan to control the banner without having to resubmit the app to Apple's App Store and Google's Play Store.",
      'The News and Event sections bring in news and events from an admin site to allow the administrators to add or remove news or events from the app.',
    ],
    tags: new Set([
      Tag['C#'],
      Tag['JavaScript'],
      Tag['CSS'],
      Tag['HTML'],
      Tag['SQLServer'],
      Tag['Phonegap'],
    ]),
    teamSize: 2,
    platforms: new Set([Platform['mobile']]),
    type: ProjectType['Close'],
    icon: 'iconCityVaughan.png',
    url: 'https://itunes.apple.com/us/app/city-of-vaughan/id896731406',
    sampleCodeUrl:
      'https://github.com/SpiritBreaker226/codesamples-CityOfVaughan',
    isFeature: false,
  },
  {
    id: 'e111ea19398940c3ae78eecbf314cf90',
    name: 'Orders',
    description:
      'Build an in-browser application that displays Orders in real-time as they are delivered from a provided server API, and allows users to search their Orders by price.',
    responsibilities: ['Everything'],
    tags: new Set([Tag['JavaScript'], Tag['CSS'], Tag['HTML']]),
    teamSize: 1,
    platforms: new Set([Platform['web']]),
    type: ProjectType['Open'],
    sampleCodeUrl: 'https://github.com/SpiritBreaker226/orders',
    isFeature: false,
  },
]
